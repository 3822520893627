import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2017-12-12T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "/reading/version-control.jpg",
        "alt": null
      }}></img></p>
    <ul>
      <li parentName="ul">{`Title: `}<strong parentName="li">{`Version Control`}</strong></li>
      <li parentName="ul">{`Dexter Palmer`}</li>
    </ul>
    <p>{`My rating: ★★★`}</p>
    <p>{`An intriguing time travel novel. I found it a bit of a slow to starter but definitely drew me in. Interesting if you know Git or other version control systems.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      